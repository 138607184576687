import React, { useEffect, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/components/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import Card from '../../Components/Card';
import Container from '../../Components/Container';
import Section from '../../Components/Section';
import styles from './styles.module.scss';
SwiperCore.use([Navigation]);

const TopRated = ({ data, propsTopRatedData }, props) => {
    const leftArrow = React.useRef(null);
    const rightArrow = React.useRef(null);
    const [topratedData, setTopRatedData] = useState(propsTopRatedData ?? []);
    useEffect(() => {
        if (topratedData !== data) {
            setTopRatedData(data);
        }
    }, [data, topratedData]);
    const NFTData = (data) => {
        return data.map((nft, index) => {
            return (
                <SwiperSlide key={index}>
                    <Card
                        className={`${styles.card__clr}`}
                        key={index}
                        {...nft}
                    />
                </SwiperSlide>
            );
        });
    };

    return (
        <Section className={`${styles.top_rated_nfts}`}>
            <Container>
                {/* <MainTitle
                    title={"Top Rated"}
                    viewAllRef={"/view-all"}
                /> */}
                <div className={styles.header}>
                    {/* <h1 className={styles.title}>What&apos;s On</h1> */}
                    <h1 className={styles.title}>Featured</h1>
                    {topratedData.length > 4 && (
                        <div className={styles.slider_btns}>
                            <span
                                // onClick={props.onPrevious}
                                ref={leftArrow}
                                className={styles.arrow_left}
                            >
                                <svg
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 13L1 7L7 1"
                                        stroke="#666666"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                            <span
                                // onClick={props.onNext}
                                ref={rightArrow}
                                className={styles.arrow_right}
                            >
                                <svg
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 13L7 7L1 1"
                                        stroke="#666666"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </div>
                    )}
                </div>
                {topratedData.length === 0 ? (
                    <p className={`${styles.nodataavalabile}`}>
                        No items are available for this category.
                    </p>
                ) : (
                    <div className={`${styles.listings} topratedSwiper`}>
                        <Swiper
                            spaceBetween={8}
                            // slidesPerView={3}
                            // slidesPerView={5}
                            // freeMode={true}
                            touchStartPreventDefault={false}
                            navigation={{
                                prevEl: leftArrow.current,
                                nextEl: rightArrow.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl =
                                    leftArrow.current;
                                swiper.params.navigation.nextEl =
                                    rightArrow.current;
                            }}
                            breakpoints={{
                                // 1: {
                                //   slidesPerView: 1,
                                //   freeMode: false,
                                //   navigation: {
                                //     prevEl: leftArrow.current,
                                //     nextEl: rightArrow.current,
                                //   },
                                //   spaceBetween: 10,
                                // },
                                // 600: { slidesPerView: 1, spaceBetween: 20 },
                                // 768: { slidesPerView: 1, spaceBetween: 20 },
                                // 992: { slidesPerView: 3 },
                                // 1200: { slidesPerView: 3 },
                                // 1400: { slidesPerView: 3 },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 0,
                                    resistanceRatio: 0.85,
                                },
                                375: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                390: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                428: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                425: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                540: {
                                    slidesPerView: 1,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 5,
                                    resistanceRatio: 0.85,
                                },
                                980: {
                                    slidesPerView: 4,
                                    spaceBetween: 28,
                                    resistanceRatio: 0.85,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 5,
                                    resistanceRatio: 0,
                                },
                                1152: {
                                    slidesPerView: 3,
                                    spaceBetween: 5,
                                    resistanceRatio: 0,
                                },
                                1280: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                    resistanceRatio: 0,
                                },
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                    resistanceRatio: 0,
                                },
                            }}
                        >
                            {NFTData(topratedData)}
                        </Swiper>
                    </div>
                )}
            </Container>
        </Section>
    );
};

export default TopRated;
